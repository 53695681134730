@import "../node_modules/bulma/bulma.sass";
@import url("https://fonts.googleapis.com/css2?family=Prompt&display=swap");

* {
  font-family: "Prompt", sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

body {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.sidebar {
  z-index: 5;
  padding: 10px;
  width: 250px;
  background-color: white;
  min-height: 100vh;
  max-height: 100%;
  transition: 0.2s;
}

.sidebar li {
  font-size: 18px;
  margin-bottom: 10px;
}

.sidebar b {
  font-weight: 400;
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: black;
  opacity: 0.3;
  z-index: 10;
}

.overlay-text {
  width: 100%;
  flex-direction: column;
  font-size: 30px;
  color: white;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 11;
}

.overlay-text svg {
  font-size: 100px !important;
}

#menu {
  display: none;
}

.select_kind {
  width: 250px !important;
  font-size: 20px !important;
}

.select_kind li {
  font-size: 18px !important;
  cursor: pointer;
  transition: 0.1s;
}

.select_kind li:hover {
  color: $link;
  font-size: 20px !important;
}

@media screen and (max-width: 950px) {
  .sidebar {
    width: 65px;
  }
  .sidebar b,
  .sidebar h4,
  .sidebar h5,
  .sidebar hr {
    display: none;
  }
  #menu {
    display: block;
  }
}
