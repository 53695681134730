@keyframes loading_upload {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes ldio-wjptittm8z-1 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-45deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes ldio-wjptittm8z-2 {
  0% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(225deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
.ldio-wjptittm8z > div:nth-child(2) {
  transform: translate(-15px, 0);
}
.ldio-wjptittm8z > div:nth-child(2) div {
  position: absolute;
  top: 26.8px;
  left: 26.8px;
  width: 80.4px;
  height: 40.2px;
  border-radius: 80.4px 80.4px 0 0;
  background: #f8b26a;
  animation: ldio-wjptittm8z-1 1s linear infinite;
  transform-origin: 40.2px 40.2px;
}
.ldio-wjptittm8z > div:nth-child(2) div:nth-child(2) {
  animation: ldio-wjptittm8z-2 1s linear infinite;
}
.ldio-wjptittm8z > div:nth-child(2) div:nth-child(3) {
  transform: rotate(-90deg);
  animation: none;
}
@keyframes ldio-wjptittm8z-3 {
  0% {
    transform: translate(127.30000000000001px, 0);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: translate(46.900000000000006px, 0);
    opacity: 1;
  }
}
.ldio-wjptittm8z > div:nth-child(1) {
  display: block;
}
.ldio-wjptittm8z > div:nth-child(1) div {
  position: absolute;
  top: 61.64px;
  left: -5.36px;
  width: 10.72px;
  height: 10.72px;
  border-radius: 50%;
  background: #e15b64;
  animation: ldio-wjptittm8z-3 1s linear infinite;
}
.ldio-wjptittm8z > div:nth-child(1) div:nth-child(1) {
  animation-delay: -0.67s;
}
.ldio-wjptittm8z > div:nth-child(1) div:nth-child(2) {
  animation-delay: -0.33s;
}
.ldio-wjptittm8z > div:nth-child(1) div:nth-child(3) {
  animation-delay: 0s;
}
.loadingio-spinner-bean-eater-hyyyx7u0h3 {
  width: 134px;
  height: 134px;
  display: inline-block;
  overflow: hidden;
  background: rgba(NaN, NaN, NaN, 0);
}
.ldio-wjptittm8z {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-wjptittm8z div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */
